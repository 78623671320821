<template>
  <div class="bg-white py-4">
    <b-container fluid class="profile" v-if="fetchedUser">
      <h1-tangerine :title="$t('notification_settings')" />

      <b-alert v-model="showErrorAlert" variant="danger" dismissible>
        <ul class="mb-0" v-if="Array.isArray(errorMessage)">
          <li v-for="(errors, index) in errorMessage" v-bind:key="index"> {{ errors[0] }}</li>
        </ul>
        <p v-else class="mb-0">
          {{ errorMessage }}
        </p>
      </b-alert>

      <b-alert v-model="showSuccessAlert" variant="success" dismissible>
        {{ $t('success_settings') }}
      </b-alert>

      <Email />

      <!--Notifications-->
      <div class="row pl-0 pr-0 pr-md-5 pl-md-5">
        <div class="col-12">
          <b-form-checkbox-group id="" class="text-left w-100" v-model="marketingNotifications.data" switches size="lg">
            <h5 class="font-weight-bold text-center text-md-left">{{ $t('anytask_marketing_options_title') }}</h5>
            <table class="table mb-0">
              <tbody>
              <tr v-for="(option, index) in marketingNotifications.default" :key="index">
                <td data-title="name" class="p-1">
                  <div class="d-flex">
                    <b-form-checkbox :id="'notification-' + index" :key="index" class="d-flex" :value="option.type" size="lg">
                      {{ option.title }}
                    </b-form-checkbox>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </b-form-checkbox-group>
          <div class="text-left mb-0 mt-3 marketing-note"><small>{{ $t('notifications_info_text') }}</small></div>
        </div>
      </div>
      <div class="d-flex mb-3 mt-3">
        <b-button v-if="!notificationLoader" class="my-2 submitBtn mx-auto btn-primary w-100" :disabled="!appNotifications.changed && !marketingNotifications.changed" v-on:click="submitData(true)">{{ $t('notification_save_button') }}</b-button>
        <b-button v-else class="my-2 submitBtn mx-auto btn-primary w-100">
          <b-spinner small label="Large Spinner"/>
        </b-button>
      </div>
      <!--End Notifications-->

      <!--Away status-->
      <div class="row border-top pt-4 pl-1 pr-0 pr-md-5 pl-md-5">
        <div class="col-md-7 col-12 ">
          <h5 class="font-weight-bold text-center text-md-left">{{ $t('away.settings_heading') }}</h5>
          <div class=" mt-1 mb-0 marketing-note text-left">{{ $t('away.settings_para') }}</div>
        </div>
        <div class="col-md-5 col-12 mt-3 mt-md-0 ">
          <b-form-radio-group
            id="away-input-settings"
            v-model="away.data.active">
            <b-form-radio class="btn radioBorder btn-outline-dark rounded w-100 mb-2" value="away">{{ $t('away.option1') }}</b-form-radio>
            <b-form-radio class="btn radioBorder btn-outline-dark rounded w-100 mb-2" value="active">{{ $t('away.option2') }}</b-form-radio>
          </b-form-radio-group>
          <b-input-group class="w-100">
            <b-form-input id="date-input"
                          v-model="away.data.date"
                          type="text"
                          :placeholder="$t('away.placeholder')"
                          autocomplete="off"/>
            <b-input-group-append class="">
              <b-form-datepicker v-model="away.data.date" button-only right :hide-header="true" :value-as-date="false" :min="setDatePickerValidation()"/>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="d-flex mb-3 mt-3 pr-0">
        <b-button v-if="!away.loader" class="my-2 submitBtn mx-auto btn-primary w-100" :disabled="away.original.date === away.data.date && away.original.active === away.data.active" v-on:click="submitData(false)">{{ $t('away.settings_button') }}</b-button>
        <b-button v-else class="my-2 submitBtn mx-auto btn-primary w-100">
          <b-spinner small label="Large Spinner"/>
        </b-button>
      </div>
      <!--end of away status-->

      <!--log out-->
      <div id="logout" class="row border-top pt-4 pb-4 pl-0 pr-0 pr-md-5 pl-md-5">
        <div class="col-md-7 col-12 col-12 ">
          <h5 class="font-weight-bold text-center text-md-left">{{ $t('logout_devices.title') }}</h5>
          <div class="mt-1 mb-0 marketing-note text-left">{{ $t('logout_devices.paragraph') }}</div>
          <p class="text-left font-italic pt-1">{{ $t('last_logged_in', { date: date(lastLoggedIn) }) }}</p>
        </div>
        <div class="col-md-5 col-12">
          <b-button class="my-2 w-100 submitBtn mx-auto btn-outline-danger bg-white" v-on:click="logMeOut">{{ $t('logout_devices.button') }}</b-button>
        </div>
      </div>
      <!--log out end-->
    </b-container>
    <div v-else>
      <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"></b-spinner>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService'
import { mapActions } from 'vuex'
import H1Tangerine from '../../components/Global/H1Tangerine'
import Email from '@/components/MyAccount/Settings/Email'

export default {
  name: 'MySettings',
  components: {
    Email,
    H1Tangerine
  },
  data () {
    return {
      fetchedUser: false,
      away: {
        data: {
          date: '',
          active: false
        },
        original: {
          date: '',
          active: false
        },
        loader: false
      },
      appNotifications: {
        original: [],
        data: [],
        default: [],
        allHashes: [],
        changed: false
      },
      marketingNotifications: {
        default: [
          { 'title': this.$t('marketing_type.1'), type: 1 },
          { 'title': this.$t('marketing_type.2'), type: 2 }
        ],
        data: [],
        original: [],
        changed: false
      },
      notificationLoader: false,
      errorMessage: '',
      showErrorAlert: false,
      showSuccessAlert: false,
      lastLoggedIn: ''
    }
  },
  watch: {
    'appNotifications.data': {
      handler: function () {
        let result = false
        const vm = this
        this.appNotifications.allHashes.forEach(function (item) {
          if ((!vm.appNotifications.data.includes(item) && vm.appNotifications.original.includes(item)) || (vm.appNotifications.data.includes(item) && !vm.appNotifications.original.includes(item))) {
            result = true
          }
        })
        this.appNotifications.changed = result
      }
    },
    'marketingNotifications.data': {
      handler: function () {
        let result = false
        const vm = this
        this.marketingNotifications.default.forEach(function (item) {
          if ((vm.marketingNotifications.original.includes(item.type) && !vm.marketingNotifications.data.includes(item.type)) || (!vm.marketingNotifications.original.includes(item.type) && vm.marketingNotifications.data.includes(item.type))) {
            result = true
          }
        })
        this.marketingNotifications.changed = result
      }
    }
  },
  mounted () {
    this.getUser()
  },
  methods: {
    ...mapActions([
      'updateRefreshToken',
      'signOut'
    ]),
    date (date) {
      const newDate = new Date(date)
      return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    },
    setDatePickerValidation () {
      let date = new Date()
      date.setDate(date.getDate() + 1)
      return date
    },
    getNotifications () {
      ApiService.getNotifications().then((data) => {
        this.appNotifications.default = data.data.data
        this.extractNotifications()
      }).catch(() => {
      })
    },
    extractNotifications () {
      let hash = []
      let allHashes = []
      const uNotifications = this.appNotifications.original
      this.appNotifications.default.forEach(function (item) {
        allHashes.push(item.hash)
        if (!uNotifications.includes(item.internal_name)) {
          hash.push(item.hash)
        }
      })
      this.appNotifications.allHashes = allHashes
      this.appNotifications.original = this.appNotifications.data = hash
    },
    getUser () {
      ApiService.getUser().then((response) => {
        this.getMarketing(response.data.data.user_marketing)
        this.appNotifications.original = response.data.data.notifications
        this.getNotifications()
        this.away.original.active = response.data.data.away ? 'away' : 'active'
        this.away.original.date = response.data.data.returning_date === null ? '' : response.data.data.returning_date
        this.away.data.active = this.away.original.active
        this.lastLoggedIn = response.data.data.last_logged_in
        this.checkDateAway()
        this.fetchedUser = true
      }).catch(() => {
      })
    },
    checkDateAway () {
      let date = this.away.original.date
      if (date !== null && date !== '') {
        let returningDate = new Date(date)
        let currentDate = new Date()

        if (returningDate > currentDate) {
          let day = returningDate.getDate()
          let month = returningDate.getMonth() + 1
          let displayDate = returningDate.getFullYear() + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day)
          this.away.original.date = displayDate
          this.away.data.date = displayDate
        } else {
          this.away.original.date = ''
          this.away.data.date = ''
          this.away.data.active = 'active'
        }
      }
    },
    getMarketing (marketing) {
      let currentMarketing = []
      marketing.forEach(function (item) {
        if (item.enabled === true) {
          currentMarketing.push(item.type)
        }
      })
      this.marketingNotifications.data = currentMarketing
      this.marketingNotifications.original = currentMarketing
    },
    createData (notifications) {
      this.showErrorAlert = false
      this.showSuccessAlert = false
      let payload = {}
      if (notifications) {
        this.notificationLoader = true
        payload.notifications = this.appNotifications.allHashes.filter(x => this.appNotifications.data.indexOf(x) === -1)
        payload.marketing = this.marketingNotifications.data
      } else {
        this.away.loader = true
        if (this.away.data.active === 'away') {
          payload.away = 1
          if (this.away.data.date !== '') {
            let date = new Date(this.away.data.date)
            payload.returning_date = {
              'date': {
                'year': date.getFullYear(),
                'month': date.getMonth() + 1,
                'day': date.getDate()
              },
              'time': {
                'hour': '0',
                'minute': '0'
              }
            }
          }
        } else {
          payload.away = 0
          this.away.data.date = ''
        }
      }
      return payload
    },
    submitData (notifications = true) {
      let payload = this.createData(notifications)
      ApiService.patchUser(payload).then(() => {
        this.showSuccessAlert = true
        this.notificationLoader = false
        this.away.loader = false

        if (notifications) {
          this.marketingNotifications.original = this.marketingNotifications.data
          this.appNotifications.original = this.appNotifications.data
          this.appNotifications.changed = false
          this.marketingNotifications.changed = false
        } else {
          this.away.original.active = this.away.data.active
          this.away.original.date = this.away.data.date
        }
        window.scrollTo(0, 0)
      }).catch((error) => {
        this.onErrorUser(error)
      })
    },
    onErrorUser (error) {
      if (error.response.data.errors !== undefined) {
        const errors = []
        if (error.response.data.errors.errors !== undefined) {
          errors.push(error.response.data.errors.errors)
        }
        if (error.response.data.errors.children.returning_date.errors !== undefined) {
          errors.push(error.response.data.errors.children.returning_date.errors)
        }
        this.errorMessage = errors
      } else {
        this.errorMessage = error.response.data.error_message
      }
      this.notificationLoader = false
      this.away.loader = false
      this.showErrorAlert = true
      window.scrollTo(0, 0)
    },
    logMeOut () {
      this.$swal({
        title: this.$t('logout_devices.title'),
        text: this.$t('logout_devices.confirm_description'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('remove_task_confirm'),
        cancelButtonText: this.$t('remove_task_cancel'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result === true) {
            return ApiService.logOutOfDevices().then((response) => {
              localStorage.setItem('refreshToken', response.data.data.refresh_token)
              this.updateRefreshToken(response.data.data.refresh_token)
            }).catch(() => {})
          }
        }
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: this.$t('success'),
            text: this.$t('logout_devices.success_message'),
            type: 'success',
            confirmButtonText: this.$t('close_button')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
#away-input-settings .custom-control-label {
  margin-left: 5%;
  width: 100%;
  cursor: pointer;
}

#away-input-settings .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
</style>

<style lang="scss" scoped>
.content {
  margin-top: 0;
}
.table thead tr{
  background: none !important;
  border: none;
}

.table th, .table td {
  border: none;
}
.marketing-note {
  line-height: 18px;
}
.radioBorder {
  cursor: default !important;
}
.radioBorder:hover {
  background: none;
  color: black;
}
@include md-up {
  .submitBtn {
    width: 36% !important;
  }
  #logout .submitBtn {
    width: 50% !important;
  }
  .datePicker {
    width: 50% !important;
  }
}
@media (min-width: 1127px) {
  .radioBorder {
    width: 46% !important;
  }
}
</style>
