<template>
  <div class="alert alert-warning d-flex flex-column flex-md-row justify-content-around" v-if="showEmailConfirm">
    <div>
      <p class="text-md-left">{{ $t('email_needs_confirming') }}</p>
      <p class="m-md-0 text-md-left">{{ $t('why_we_authenticate_email') }}</p>
    </div>
    <div class="align-self-center">
      <button class="btn btn-outline-warning w-100 font-weight-bold" @click="resendEmail()" v-if="!apiCalled">{{ $t('confirm') }}</button>
      <button class="btn btn-outline-warning w-100" v-else><b-spinner small label="Small Spinner" /></button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ApiService from '@/apiService'

export default {
  name: 'email',
  mounted () {
    this.buildUser()
  },
  data () {
    return {
      user: this.$store.getters.getUser(),
      apiCalled: false
    }
  },
  methods: {
    ...mapActions([
      'buildUser',
      'fetchNewAccessToken'
    ]),
    resendEmail () {
      this.apiCalled = true
      ApiService.confirmEmail().then(() => {
        this.$swal({
          title: this.$t('success'),
          text: this.$t('email_confirmation__sent'),
          type: 'success',
          confirmButtonText: this.$t('close_button')
        })
        this.apiCalled = false
      }).catch((error) => {
        this.apiCalled = false
        switch (error.response.status) {
          case 400:
            this.$swal({
              text: this.$t('email_confirmation_already_sent'),
              type: 'info',
              confirmButtonText: this.$t('close_button')
            })
            break
          case 409:
            this.fetchNewAccessToken().then(() => {
              this.user = this.$store.getters.getUser
              this.$swal({
                text: this.$t('email_confirmed'),
                type: 'success',
                confirmButtonText: this.$t('close_button')
              })
            })
            break
          default:
            this.$swal({
              text: this.$t('error_sending_email_confirmation'),
              type: 'warning',
              confirmButtonText: this.$t('close_button')
            })
        }
      })
    }
  },
  computed: {
    showEmailConfirm () {
      return !this.user.emailConfirmed
    }
  }
}
</script>
